import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
// import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
// import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
// import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import AwsomeEvent from "images/AE.png"
import BBash from "images/BBash.png"
import Button from 'react-bootstrap/Button'
import Modal from 'react-awesome-modal'
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
// import modal from 'bootstrap/dist/css/bootstrap.min.css';

function MyModal(props){
  const headCode=`
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <script src='https://kit.fontawesome.com/a076d05399.js'></script>
`
  const styleCode=`
  body {font-family: Arial, Helvetica, sans-serif;}
  * {box-sizing: border-box;}
  
  /* Button used to open the chat form - fixed at the bottom of the page */
  .open-button {
    background-color: #1E86DB;
    color: white;
    padding: 16px 20px;
    border: 0px;
    cursor: pointer;
    position: fixed;
    bottom: 23px;
    right: 28px;
    border-radius: 50%;
    display: inline-block;
    outline: none;
    width: 70px;
    font-size:24px;
  }
  
  /* The popup chat - hidden by default */
  .chat-popup {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
    margin-bottom: 80px;
  }
`
  const bodyCode=`
  <button id="chatButton" class="open-button fas fa-comment" onclick="openChat()" size></button>
  <!-- SRC will be the link of your chatbot, in this case it is  https://ad-messenger.netlify.app-->
  <div class="chat-popup" id="myChat">
    <iframe src="https://ad-messenger.netlify.app/" height="500px" width="350px" frameborder="0"></iframe>
  </div>
`
  const scriptCode = `
  function openChat() {
    if(document.getElementById("myChat").style.display === "inline-block"){
      document.getElementById("myChat").style.display = "none";
        document.getElementById("chatButton").className="open-button fas fa-comment"
    }
    else{
      document.getElementById("myChat").style.display = "inline-block";
        document.getElementById("chatButton").className="open-button fas fa-comment-slash"
      }
  }  
`
  const [headCopy, headCopyDone] = useState(false)
  const [styleCopy, styleCopyDone] = useState(false)
  const [bodyCopy, bodyCopyDone] = useState(false)
  const [scriptCopy, scriptCopyDone] = useState(false)
  const modalStyle = {
    backgroundColor: '#DCD3D1',
    padding: '5% 5%',
    borderRadius: '10px',
    textAlign: 'center',
    height: '100%',
    overFlow: 'auto'
  };
  return(
    <Modal
      visible={props.modalIsOpen}
      effect="fadeInDown"
      width="80%"
      height="80%"
      onClickAway={() => props.setModalIsOpen(false)}
    >
      <div style={modalStyle}>
          <i
            class='fa fa-times-circle'
            style={{fontSize:30, color: 'black', position: 'absolute', top: 10, right: 10}} 
            onClick={() => props.setModalIsOpen(false)} 
          />
          <p style={{fontSize: 25}}><strong>Easy Integration</strong></p>
          <small>(Copy the code snippets by clicking below !)</small>
          <div style={{marginTop: 10,backgroundColor: '#fff', borderRadius: 20}}>
            {headCopy===false?(
            <p style={{color: 'black', padding: 5}}>
              <strong>HEAD</strong>&nbsp;&nbsp;
              <CopyToClipboard text={headCode} onCopy={() => {headCopyDone(true); styleCopyDone(false); scriptCopyDone(false); bodyCopyDone(false)} }>
                <button class="far fa-clipboard" style={{fontSize:25, color: 'black'}} onclick="openChat()" />
              </CopyToClipboard>
            </p>):<i style={{color: '#73CB45', padding: 15}}>Copied! Now paste it in the <strong>&lt;head&gt;</strong> tag</i>
            }
          </div>
          <div style={{marginTop: 10,backgroundColor: '#fff', borderRadius: 20}}>
            {styleCopy===false?(
            <p style={{color: 'black', padding: 5}}>
              <strong>STYLE</strong>&nbsp;&nbsp;
              <CopyToClipboard text={styleCode} onCopy={() => {headCopyDone(false); styleCopyDone(true); scriptCopyDone(false); bodyCopyDone(false)}}>
                <button class="far fa-clipboard" style={{fontSize:25, color: 'black'}} onclick="openChat()" />
              </CopyToClipboard>
            </p>):<i style={{color: '#73CB45', padding: 15}}>Copied! Now paste it in the <strong>&lt;style&gt;</strong> tag</i>
            }
          </div>
          <div style={{marginTop: 10,backgroundColor: '#fff', borderRadius: 20}}>
            {bodyCopy===false?(
            <p style={{color: 'black', padding: 5}}>
              <strong>BODY</strong>&nbsp;&nbsp;
              <CopyToClipboard text={bodyCode} onCopy={() => {headCopyDone(false); styleCopyDone(false); scriptCopyDone(false); bodyCopyDone(true)}}>
                <button class="far fa-clipboard" style={{fontSize:25, color: 'black'}} onclick="openChat()" />
              </CopyToClipboard>
            </p>):<i style={{color: '#73CB45', padding: 15}}>Copied! Now paste it in the <strong>&lt;body&gt;</strong> tag</i>
            }
          </div>
          <div style={{marginBottom:20, marginTop: 10,backgroundColor: '#fff', borderRadius: 20}}>
            {scriptCopy===false?(
            <p style={{color: 'black', padding: 5}}>
              <strong>SCRIPT</strong>&nbsp;&nbsp;
              <CopyToClipboard text={scriptCode} onCopy={() => {headCopyDone(false); styleCopyDone(false); scriptCopyDone(true); bodyCopyDone(false)}}>
                <button class="far fa-clipboard" style={{fontSize:25, color: 'black'}} onclick="openChat()" />
              </CopyToClipboard>
            </p>):<i style={{color: '#73CB45', padding: 15}}>Copied! Now paste it in the <strong>&lt;script&gt;</strong> tag</i>
            }
          </div>
          {headCopy===true ? (
            <div style={{borderRadius: 10, padding: 20, backgroundColor: '#fff', textAlign: 'left'}}>
              <p style={{color: 'black'}}>
                &lt;!DOCTYPE html&gt;<br />
                &lt;html&gt;<br />
                <div style={{color: 'red'}}><strong>&lt;head&gt;<br />
                <small style={{color: 'grey'}}>--paste the code here--</small><br />
                &lt;/head&gt;<br /></strong></div>
                &lt;style /&gt;<br />
                &lt;body /&gt;<br />
                &lt;script /&gt;<br />
                &lt;/html&gt;<br />
              </p>
            </div>):null
          }
          {styleCopy===true?(
            <div style={{borderRadius: 10, padding: 15, backgroundColor: '#fff', textAlign: 'left'}}>
              <p style={{color: 'black'}}>
                &lt;!DOCTYPE html&gt;<br />
                &lt;html&gt;<br />
                &lt;head /&gt;<br />
                <div style={{color: 'red'}}><strong>&lt;style&gt;<br />
                <small style={{color: 'grey'}}>--paste the code here--</small><br />
                &lt;/style&gt;<br /></strong></div>
                &lt;body /&gt;<br />
                &lt;script /&gt;<br />
                &lt;/html&gt;<br />
              </p>
            </div>):null
          }
          {bodyCopy===true?(
            <div style={{borderRadius: 10, padding: 15, backgroundColor: '#fff', textAlign: 'left'}}>
              <p style={{color: 'black'}}>
                &lt;!DOCTYPE html&gt;<br />
                &lt;html&gt;<br />
                &lt;head /&gt;<br />
                &lt;style /&gt;<br />
                <div style={{color: 'red'}}><strong>&lt;body&gt;<br /></strong>
                <small style={{color: 'grey'}}>--paste the code here--</small><br />
                <strong>&lt;/body&gt;<br /></strong></div>
                &lt;script /&gt;<br />
                &lt;/html&gt;<br />
              </p>
            </div>):null
          }
          {bodyCopy===false && scriptCopy===false && styleCopy===false && headCopy===false ?(
            <div style={{borderRadius: 10, padding: 15, backgroundColor: '#fff', textAlign: 'left'}}>
              <p style={{color: 'black'}}>
                &lt;!DOCTYPE html&gt;<br />
                &lt;html&gt;<br />
                &lt;head /&gt;<br />
                &lt;style /&gt;<br />
                &lt;body /&gt;<br />
                &lt;script /&gt;<br />
                &lt;/html&gt;<br />
              </p>
            </div>):null
          }
          {scriptCopy===true?(
            <div style={{borderRadius: 10, padding: 15, backgroundColor: '#fff', textAlign: 'left'}}>
              <p style={{color: 'black'}}>
                &lt;!DOCTYPE html&gt;<br />
                &lt;html&gt;<br />
                &lt;head /&gt;<br />
                &lt;style /&gt;<br />
                &lt;body /&gt;<br />
                <div style={{color: 'red'}}><strong>&lt;script&gt;<br />
                <small style={{color: 'grey'}}>--paste the code here--</small><br />
                &lt;/script&gt;<br /></strong></div>
                &lt;/html&gt;<br />
              </p>
            </div>):null
          }
      </div>
    </Modal>
  );
}
// &nbsp;&nbsp;&nbsp;&nbsp;&lt;meta name="viewport" content="width=device-width, initial-scale=1"&gt;<br />
// &nbsp;&nbsp;&nbsp;&nbsp;&lt;script src='https://kit.fontawesome.com/a076d05399.js'&gt;&lt;/script&gt;<br />
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2  bg-blue-700 hocus:bg-blue-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

// const RatingsInfo = styled.div`
//   ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
//   svg {
//     ${tw`w-6 h-6 text-yellow-500 fill-current`}
//   }
// `;
// const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl px-6 py-5 sm:px-10 sm:py-5 bg-blue-800 inline-block hocus:bg-blue-900`;

// const StyledModal = styled(ReactModalAdapter)`
//   &.mainHeroModal__overlay {
//     ${tw`fixed inset-0 z-50`}
//   }
//   &.mainHeroModal__content {
//     ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
//   }
//   .content {
//     ${tw`w-full lg:p-16`}
//   }
// `;
// const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "https://cdn3.f-cdn.com/contestentries/875607/19400184/58172cdf85fb4_thumb900.jpg",
      title: "Real Estate",
      description: "This is a real estate chatbot, it simply provides you information about real estate for that company saving you time instead of browsing the huge websites",
      locationText: "Acquired Real Estate Investment, Delhi",
      // pricingText: "USD 99/Day",
      // rating: 4.5,
      url: "https://real-estate.amessenger.in/"
    },
  
    {
      imageSrc: "https://cdn-a.william-reed.com/var/wrbm_gb_food_pharma/storage/images/publications/cosmetics/cosmeticsdesign.com/article/2019/12/03/how-to-get-water-reduction-formulation-right/10431162-1-eng-GB/How-to-get-water-reduction-formulation-right_wrbm_large.jpg",
      title: "Water Crisis Survey",
      description: "On going water crisis needs a survey for reaching and providing the appropriate help, instead of traditional feedback forms this is a more intuitive way to get the information from the people",
      locationText: "Karnataka",
      // pricingText: "USD 19/Day",
      // rating: "5.0",
      url: "https://water-crisis-survey.amessenger.in/"
    },
  
    {
      imageSrc: "https://loebig.files.wordpress.com/2018/12/facebook-ads-manager.jpg?w=640",
      title: "Ad Manager",
      description: "This is a chatbot for ad Management company which manages your ads on facebook, it lets you have a conversation in cool way",
      locationText: "Ads Manager FB,Pune",
      // pricingText: "USD 19/Day",
      // rating: "5.0",
      url: "https://ad-manager.amessenger.in/"
    },
    
    {
      imageSrc: AwsomeEvent,
      title: "Event Management",
      description: "Event management websites are great but for some not so up to date with tech are not generally into big websites, instead this chatbot will provide you the information necessary",
      locationText: "Awesome Events, Mumbai",
      // pricingText: "USD 50/Day",
      // rating: 4.9,
      url: "https://event-management.amessenger.in/"
    },


    {
      imageSrc: "https://www.punekarnews.in/wp-content/uploads/2020/03/grocery.jpg",
      title: "CandidWala Groceries",
      description: "Amidst this pandemic everyone is avoiding huge gatherings and long Q! we present the ultimate solution, a chatbot to book a pickup",
      locationText: "Karnataka",
      // pricingText: "USD 19/Day",
      // rating: "5.0",
      url: "https://grocery-store.amessenger.in/"
    },
  
    {
      imageSrc:BBash,
      
      title: "E-Commerce Bot",
      description: "There are many tech startups/companies handling E-Commerce however, not everyone can afford it! This chatbot provides you with cart selection and you as the owner will receive the data in an intuitive way with our awesome dashboard",
      locationText: "Breadbash, Pune",
      // pricingText: "USD 39/Day",
      // rating: "4.8",
      url: "https://e-commerce.amessenger.in/"
    },    
  ]
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Our Chatbots</Heading>
          <Controls>
            <Button variant="light" style={{color: '#2B6CB0'}} onClick={toggleModal}><strong>Easy Integration Demo</strong></Button>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText>
                  {/* <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText> */}
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton onClick={() => window.open(card.url)} >Take a Demo</PrimaryButton>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
    <MyModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}/>
    </>
  );
};
