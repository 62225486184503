import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
 import serverRedundancyIllustrationImageSrc from "images/analytics.jpg"
//  import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"
import SliderCard from "components/cards/ThreeColSlider.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <div id="demo">
        <SliderCard />
      </div>
      <div id="features">
        <Features />
      </div>
      <div id = "analytics">
        <MainFeature 
          subheading="Customized Dashboards"
          heading="Real-Time Analytics support"
          imageSrc={serverRedundancyIllustrationImageSrc}
          buttonRounded={false}
        />
      </div>
      <div id="prices" >
        <Pricing />
      </div>
      <div id="aboutus">
        <Testimonial />
      </div>
      <div id="faq">
        <FAQ />
      </div>
      <div id="contact" >
        <Footer />
      </div>
    </AnimationRevealPage>
  );
}
